import http from "@/libs/http";

export function getItems(params) {
    return http.get('data/getItems', params);
}

export function doSearch(params) {
    return http.get('data/search', params);
}

export function getCols(params) {
    return http.get('data/getCols', params);
}

export function queryProp(params) {
    return http.post('data/queryProp', params);
}