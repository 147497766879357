<!--数据-->
<template>
  <div class="container data-details-box">
    <div class="row">
      <div class="col-md-12">
        <h1 class="text-capitalize text-dets">{{ this.tableCN }}</h1>
      </div>
    </div>
    <div class="row">
      <div class="col-md-9">
        <b-tabs content-class="mt-3">
          <b-tab title="下载数据" active>
            <div class="row part-one left-box bg-color">
              <div class="col-md-6">
                <div class="part-box">
                  <p class="tabs-part">
                    固废类型
                  </p>
                  <b-form-group>
                    <b-form-checkbox-group
                        v-model="typeSelected"
                        :options="type.option"
                        value-field="columnEN"
                        text-field="columnCN"
                        style="text-align: left;height: 200px;overflow-y: auto"
                        name="flavors"
                        class="ml-4"
                        stacked
                    ></b-form-checkbox-group>
                    <b-form-checkbox
                        v-model="type.isAllSelected"
                        :indeterminate="type.indeterminate"
                        aria-describedby="type.option"
                        aria-controls="type.option"
                        @change="toggleAll(type.isAllSelected, 'type')"
                        button-variant="light"
                        button
                    >
                      {{ type.isAllSelected ? '取消全部' : '选择全部' }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div class="col-md-6">
                <div class="part-box">
                  <p class="tabs-part">
                    属性
                  </p>
                  <b-form-group>
                    <b-form-checkbox-group
                        v-model="propSelected"
                        :options="prop.option"
                        value-field="columnEN"
                        text-field="columnCN"
                        style="text-align: left;height: 200px;overflow-y: auto"
                        name="flavors"
                        class="ml-4"
                        stacked
                    ></b-form-checkbox-group>
                    <b-form-checkbox
                        v-model="prop.isAllSelected"
                        :indeterminate="prop.indeterminate"
                        aria-describedby="prop.option"
                        aria-controls="prop.option"
                        @change="toggleAll(prop.isAllSelected, 'prop')"
                        button-variant="light"
                        button
                    >
                      {{ prop.isAllSelected ? '取消全部' : '选择全部' }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div class="col-md-6">
                <div class="part-box">
                  <p class="tabs-part">
                    年份
                  </p>
                  <b-form-group>
                    <b-form-checkbox-group
                        v-model="yearSelected"
                        :options="year.option"
                        value-field="columnEN"
                        text-field="columnCN"
                        style="text-align: left;height: 200px;overflow-y: auto"
                        name="flavors"
                        class="ml-4"
                        stacked
                    ></b-form-checkbox-group>
                    <b-form-checkbox
                        v-model="year.isAllSelected"
                        :indeterminate="year.indeterminate"
                        aria-describedby="year.option"
                        aria-controls="year.option"
                        button-variant="light"
                        @change="toggleAll(year.isAllSelected, 'year')"
                        button
                    >
                      {{ year.isAllSelected ? '取消全部' : '选择全部' }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </div>
              <div class="container-fluid from-group">
                <div class="row">
                  <div class="col-sm-2 col-md-2">
                    <h6>文件格式</h6>
                    <b-form-radio-group
                        v-model="fileTypeSelected"
                        :options="fileTypeOptions"
                        name="file-type"
                    ></b-form-radio-group>
                  </div>
                  <div class="col-sm-3 col-md-3">
                    <h6>输出格式选项</h6>
                    <b-form-radio-group
                        v-model="outTypeSelected"
                        :options="outTypeOption"
                        name="out-type"
                    ></b-form-radio-group>
                  </div>
                  <div class="col-sm-2 col-md-2">
                    <h6>输出列名选项</h6>
                    <b-form-radio-group
                        v-model="colLangSelected"
                        :options="colLangOption"
                        name="col-lang"
                    ></b-form-radio-group>
                  </div>
                </div>
                <div class="row operation">
                  <div class="hidden-xs col-md-5"></div>
                  <b-button squared variant="primary" class="col-md-3" @click="searchData()">
                    <b-icon icon="search"></b-icon>
                    查询数据
                  </b-button>
                  <b-button squared variant="info" class="col-md-3" @click="downloadData()">
                    <b-icon icon="download"></b-icon>
                    下载数据
                  </b-button>
                </div>
              </div>
            </div>
            <div class="row data-table">
              <b-card
                  title="数据视图">
                <b-table sticky-header striped bordered head-variant="light" :items="dataTable"></b-table>
              </b-card>
            </div>
          </b-tab>
          <!--<b-tab title="可视化数据" @click="linkClass()">
            <div class="row part-tow">
              <div class="col-md-3">
                <h5>Groups</h5>
                <v-select v-model="selected2" :options="['Canada', 'United States']"></v-select>
              </div>
              <div class="col-md-3">
                <h5>Groups</h5>
                <v-select v-model="selected2" :options="['Canada', 'United States']"></v-select>
              </div>
              <div class="col-md-2">
                <h5>Groups</h5>
                <v-select multiple v-model="selected2" :options="['Canada', 'United States']"></v-select>
              </div>
              <div class="col-md-2">
                <h5>Groups</h5>
                <v-select multiple v-model="selected2" :options="['Canada', 'United States']"></v-select>
              </div>
              <div class="col-md-2">
                <h5>Groups</h5>
                <v-select multiple v-model="selected2" :options="['Canada', 'United States']"></v-select>
              </div>
            </div>
            <div class="charts-part">
              <div ref="charts" class="echarts-content"></div>
            </div>
          </b-tab>-->
        </b-tabs>
      </div>
      <div class="col-md-3 ">
        <div class="right-box bg-color">
          <div class="tit">{{ this.tableCN }}</div>
          <div class="part-s">
            {{ this.tableInfo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {getCols, queryProp} from "@/api/data";
import json2csv from "json2csv";
import fileSaver from 'file-saver'

export default {
  name: 'Data',
  data() {
    return {
      tableEN: '',
      search1: '',
      tableCN: '',
      tableInfo: '',
      propSelected: [],
      typeSelected: [],
      yearSelected: [],
      prop: {
        isAllSelected: false,
        indeterminate: false,
        option: []
      },
      type: {
        isAllSelected: false,
        indeterminate: false,
        option: []
      },
      year: {
        isAllSelected: false,
        indeterminate: false,
        option: []
      },
      fileTypeSelected: 'csv',
      fileTypeOptions: [{
        text: 'CSV', value: 'csv'
      }, {
        text: 'XLS', value: 'xls'
      }],
      outTypeSelected: 'null',
      outTypeOption: [{
        text: '显示空值', value: 'null'
      }, {
        text: '不显示空值', value: 'noNull'
      }],
      colLangSelected: 'en',
      colLangOption: [{
        text: '英文', value: 'en'
      }, {
        text: '中文', value: 'cn'
      }],
      dataTable: [],
      active: false,
      selected2: "",
      option: {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [{
          data: [820, 932, 901, 934, 1290, 1330, 1320],
          type: 'line'
        }]
      },

    }
  },
  methods: {
    tabsSwitch(tabIndex) {
      for (var i = 0; i < this.tabsName.length; i++) {
        this.tabsName[i].isActive = false;
      }
      this.tabsName[tabIndex].isActive = true
    },
    initChart() {
      var self = this;
      self.charts = self.$echarts.init(self.$refs.charts);
      self.charts.setOption(self.option);

      setTimeout(function () {
        self.charts.resize();
      })
    },
    linkClass() {
      let self = this;
      self.initChart()
    },
    toggleAll(checked, option) {
      // eslint-disable-next-line no-debugger
      // debugger
      let arr = [];
      if ('type' === option) {
        this.type.option.forEach(o => {
          arr.push(o.columnEN)
        })
        this.typeSelected = checked ? arr : []
      } else if ('prop' === option) {
        this.prop.option.forEach(o => {
          arr.push(o.columnEN)
        })
        this.propSelected = checked ? arr : []
      } else {
        this.year.option.forEach(o => {
          arr.push(o.columnEN)
        })
        this.yearSelected = checked ? arr : []
      }
    },
    filter1() {
      // eslint-disable-next-line no-debugger
      // debugger
      const that = this;
      this.options1.filter(function (prop) {
        return Object.keys(prop).some(function (key) {
          return String(prop[key]).toLowerCase().indexOf(that.search1) > -1
        })
      })
    },
    searchData() {
      let cn;
      cn = this.colLangSelected !== 'en';
      let params = {
        table: this.tableEN,
        wasteType: this.typeSelected,
        prop: this.propSelected,
        year: this.yearSelected,
        cn: cn
      }
      queryProp(params).then(res => {
        // console.log(res)
        const {data} = res
        this.dataTable = data
      }).catch(() => {

      })
    },
    downloadData() {
      let cn;
      cn = this.colLangSelected !== 'en';
      let params = {
        table: this.tableEN,
        wasteType: this.typeSelected,
        prop: this.propSelected,
        year: this.yearSelected,
        cn: cn
      }
      queryProp(params).then(res => {
        // console.log(res)
        const {data} = res

        let filename;
        if (this.colLangSelected === 'en') {
          filename = 'Data'
        } else {
          filename = '数据'
        }
        // eslint-disable-next-line no-debugger
        debugger
        const cvsData = json2csv.parse(data)
        const blob = new Blob([cvsData], {type: 'text/plain;charset=utf-8;'})
        fileSaver.saveAs(blob, filename + '.' + this.fileTypeSelected)
      }).catch(() => {

      })
    }
  },
  mounted() {
    this.tableEN = this.$route.params.tableEN
    this.tableCN = this.$route.params.tableCN

    getCols({tableName: this.tableEN}).then(res => {
      // eslint-disable-next-line no-debugger
      // debugger
      const {data} = res
      this.type.option = data.wasteType;
      this.prop.option = data.prop;
      this.year.option = data.year;
      this.tableInfo = data.tableInfo;
    }).catch(() => {

    })
  },
  created() {

  },
  watch: {
    propSelected(newVal) {
      // eslint-disable-next-line no-debugger
      // debugger
      if (newVal.length === 0) {
        this.indeterminate = false
        this.prop.isAllSelected = false
      } else if (newVal.length === this.prop.option.length) {
        this.indeterminate = false
        this.prop.isAllSelected = true
      } else {
        this.indeterminate = true
        this.prop.isAllSelected = false
      }
    },
    typeSelected(newVal) {
      // eslint-disable-next-line no-debugger
      // debugger
      if (newVal.length === 0) {
        this.indeterminate = false
        this.type.isAllSelected = false
      } else if (newVal.length === this.type.option.length) {
        this.indeterminate = false
        this.type.isAllSelected = true
      } else {
        this.indeterminate = true
        this.type.isAllSelected = false
      }
    },
    yearSelected(newVal) {
      // eslint-disable-next-line no-debugger
      // debugger
      if (newVal.length === 0) {
        this.indeterminate = false
        this.year.isAllSelected = false
      } else if (newVal.length === this.year.option.length) {
        this.indeterminate = false
        this.year.isAllSelected = true
      } else {
        this.indeterminate = true
        this.year.isAllSelected = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/less/data.less";

.echarts-content {
  height: 450px;
  width: 100%;
}
</style>